import React, { Suspense, useEffect } from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import * as serviceWorker from "./serviceWorker"

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css"

import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom"
import { setBrandingLanguage } from "./branding/branding"
import { getActiveLanguage } from "./globalStates/LanguageState"
import CenteredLoader from "./ui/CenteredLoader"
import { useExhibitorDataState } from "./globalStates/ExhibitorDataState"
import { useProductPropertiesDataState } from "./globalStates/ProductPropertiesState"
import { useReferenceDataState } from "./globalStates/ReferenceDataState"
// import { useNotificationsDataState } from './globalStates/NotificationDataState';
import { useLoadingState } from "./globalStates/LoadingState"
import { parseCredentials } from "./utils/Params"
import { useConfigDataState } from "./globalStates/ConfigDataState"

const App = React.lazy(() => import("./App"))

function Application() {
    const loadingState = useLoadingState()
    const search = useLocation().search
    const history = useHistory()
    const credentials = parseCredentials(search)
    const refDataState = useReferenceDataState()
    const cfgDataState = useConfigDataState()
    const exState = useExhibitorDataState()
    const productPropertiesState = useProductPropertiesDataState()
    // const notifState = useNotificationsDataState()
    useEffect(() => {
        loadingState.setActive(true, "Please wait while loading takes place ...")
        setBrandingLanguage(getActiveLanguage() as any)
        productPropertiesState.init(credentials.exhibitorId, credentials.accessToken)
        cfgDataState
            .init(credentials.exhibitorId, credentials.accessToken)
            .then(() => refDataState.init())
            .then(() =>
                exState.init(credentials.exhibitorId, credentials.accessToken, cfgDataState.getServiceKeys(), refDataState)
            )
            .then(() => {
                loadingState.setActive(false, "")
            })
            .catch((error) => {
                loadingState.setActive(false, "")
                console.log(error)
                history.replace("/error")
                // notifState.addError(error)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <App />
}

function render() {
    const container = document.getElementById("event-cloud-selfservice")
    const root = createRoot(container!)
    root.render(
        <React.StrictMode>
            <Suspense fallback={<CenteredLoader>Loading...</CenteredLoader>}>
                <Router>
                    <Application />
                </Router>
            </Suspense>
        </React.StrictMode>
    )
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
